import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms/";

import PhoneNumber from 'awesome-phonenumber'


import { debounceTime } from 'rxjs/operators';

import { OnboardingPage } from '../onboarding/onboarding.page';

import { LogService } from '../log.service';
import { MessagingService} from '../messaging.service';
import { ProfileService } from '../profile.service';
import { UtilitiesService } from '../utilities.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.page.html',
  styleUrls: ['./notifications.page.scss'],
})
export class NotificationsPage implements OnInit {

	SMSForm: FormGroup;
	SMSFormValid: boolean = true // defaults to true, so if the study does not have SMS notifications 
	phoneNumber: string; // defaults to undefined


	// the notification 'submit' button is not deactivated
	notificationsForm: FormGroup;
	noteTimesPatchObj: object = {}; // used to patch/update the form to the users settings
	notificationTimesArray: string[] = [];
	frequency: number; // the number of reminders per day
	numNotificationTimes: number; // the number of notification times to set
	isNotificationsComplete: boolean = false
	isNotificationsReasonable: boolean = true; // makes sure they are not too close together
	// notificationType: string = 'email' // hard-code it, and leave it as a reminder if I need to ever bring it back

	noteSchedule: number[];

	isRandomTimes: boolean = false;
	smsAllowed: boolean = false;


	constructor(public modalCtrl: ModalController, 
		formBuilder: FormBuilder,
		public log: LogService,
		public msgService: MessagingService,
		public profile: ProfileService,
		public utils: UtilitiesService
	) {

		this.notificationsForm = formBuilder.group({})

		this.notificationsForm.valueChanges.pipe(debounceTime(550)).subscribe(res => {
			this.isNotificationsComplete = this.notificationsForm.valid
			// if(! this.notificationsForm.valid) return
			this.noteSchedule = Object.keys(res).map(key => {
				return this.utils.localToServerTime(res[key]) // parses string to int
			}).sort((a, b) => a - b)
			this.testNotificationsTimesValid()
		})

		this.SMSForm = formBuilder.group({})
		let SMScontrol = formBuilder.control("")
			// [Validators.required, 
			// [	Validators.pattern("[0-9 ]{5}[0-9 ]+"),
			// 	Validators.maxLength(12)])
		this.SMSForm.addControl("phoneNumber", SMScontrol)

		this.SMSForm.valueChanges.pipe(debounceTime(50)).subscribe(form => {
			let pn
			if(form.phoneNumber.substr(0,3) ==="+49") {
				pn = new PhoneNumber(form.phoneNumber)
			} else {
				pn = new PhoneNumber(form.phoneNumber, "DE")
			}
			this.phoneNumber = pn.getNumber('e164')
			this.SMSFormValid = pn.isMobile() || form.phoneNumber===''
			console.log( "phone number", form.phonenumber, this.SMSFormValid)
		})

		// subscribe to user settings and study settings
		this.profile.userSettings.subscribe(res => {
			if(res === null ) return
			if(!res.studyID){
				// this case should never happen
				this.presentOnboardingModal()
				return
			}
			if(res.phoneNumber){
				this.SMSForm.patchValue({
					"phoneNumber": res.phoneNumber
				})
			}
			if(! Array.isArray(res.notificationSchedule)) return
			// convert notifications settings to form object
			if(res.notificationSchedule.indexOf(NaN) > -1) return
			this.noteTimesPatchObj = {}
			res.notificationSchedule.map(hour =>
				 this.utils.serverToLocalTime(hour)
			).sort((a,b) =>  a - b)
			.forEach((hour, index) => {
				this.noteTimesPatchObj['input_' + index] = hour
			})
			this.noteTimesPatchObj['isSet'] = res.notificationSchedule.length > 0
			if(this.noteTimesPatchObj['isSet']){
				this.notificationsForm.patchValue(this.noteTimesPatchObj)
			}
			this.noteSchedule=res.notificationSchedule
			this.testNotificationsTimesValid()
	})

		this.log.currentStudySubject.subscribe(res => {
			// console.log( "notifications setter sees studyID:", res.studyID,
			// 	"with", res.frequency, "notifications" )
			// console.log( this.notificationsForm.controls )
			// this.notificationsForm = formBuilder.group({})
			// this.notificationTimesArray = []
			if(!res) return
			let counter = 0
			// frequency in the study is # notifications per day
			this.frequency = res.frequency
			this.numNotificationTimes = res.frequency
			// Random notifications have 'frequency' of 2
			//  the earliest and latest times for notifications
			if(res.isRandomTimes){
				this.isRandomTimes = true
				this.numNotificationTimes = 2
				this.isNotificationsReasonable = false
			}
			do {
				let tmpName = 'input_' + counter
				if(! this.notificationsForm.contains(tmpName)){
					let tmpControl = new FormControl('',
						[Validators.required, Validators.min(0), Validators.max(24)])
					this.notificationsForm.addControl(tmpName, tmpControl)
					this.notificationTimesArray.push(tmpName)
				}
				counter++
			} while (counter < this.numNotificationTimes)
				if(this.noteTimesPatchObj['isSet']) {
					this.notificationsForm.patchValue(this.noteTimesPatchObj)
					this.isNotificationsComplete = this.notificationsForm.valid
				}
			this.testNotificationsTimesValid()
			if(res.isSMSAllowed==='yes'){
				this.smsAllowed = true
			}
		})


	}


	/**
	 * testNotificationsTimesValid(){
	 *
	 * Tests if notification times are valid for random-timed studies
	 *
	 *	Default is that random studies are not valid, but others are
	 *
	 **/
	testNotificationsTimesValid(){
		// console.log( "testing", this.noteSchedule )
		if(!this.noteSchedule) { return }
		if(!this.isRandomTimes) { return }
		if(this.noteSchedule.length != 2) { return } // the delta test would also take care of this...
		let delta = Math.max(...this.noteSchedule) - Math.min(...this.noteSchedule)
		this.isNotificationsReasonable = delta > (1.3 * this.frequency)
	}


	ngOnInit() {}

  ngAfterViewInit() { }

	ionViewWillLeave(){
		// save/update phone number and notification type
		let cleanNumber = ''
		if(this.phoneNumber) {
			let pn = new PhoneNumber(this.phoneNumber)
			cleanNumber=pn.getNumber('e164')
		}

		this.msgService.updateSMSNumber(cleanNumber)
			.then(() => this.msgService.updateNotificationSchedule(this.noteSchedule))
			.then(() => this.msgService.getNotificationSchedule()  )
			.then(notificationDoc => {
				// console.log( "should update settings", notificationDoc )
				let updateObj = [
					'notificationType',
					'notificationSchedule',
					'randomNotificationSchedule',
					'phoneNumber'
				].reduce((obj, key) => {obj[key]=notificationDoc[key]; return obj;} ,
					{})
				// if(updateObj['phoneNumber'] === undefined || updateObj['phoneNumber'] === ''){
				// 	delete updateObj['phoneNumber']
				// }
				this.profile.requestUpdateSettings(updateObj)
			})
			.catch(err => { console.warn( err )})
	}


	/**
	 * dismissModal
	 * @param answer
	 * Closes the modal, passing any text string back to the caller
	 * Uses a timeout to allow writes to Firebase, so user's onboarding
	 * status updates. Hack.
	 * */
  dismissModal(answer: string){
		if(this.isNotificationsComplete && this.isNotificationsReasonable){ // hack nav guard 
			setTimeout( ()=> {
				this.modalCtrl.dismiss({response: answer })
			}, 50)
		}
	}


	/**
	 * openOnboarding
	 * Opens the onboarding modal
	 * Useful if i.e. study is not found
	 * */
	async presentOnboardingModal(){
		const messagingModal = await this.modalCtrl.create({
			component: OnboardingPage,
			componentProps: {
				// doneButtonText: "WEITER" // from the homepage version of this function
				// source: 'notifications page' ;; it's in the original, not sure why it is needed
			}
		})

		messagingModal.onDidDismiss().then( (data) => {
			if(data){  }
		})

		return await messagingModal.present() 
	}

}
