import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/firestore";
import 'firebase/firestore';
import { timeFormat } from 'd3-time-format';
import { timeDay } from 'd3-time';
import { nest } from 'd3-collection';
import { BehaviorSubject } from "rxjs";
import { Subject } from "rxjs";
import { pipe } from 'rxjs';
import { take, switchMap, tap } from 'rxjs/operators';

import { ProfileService } from "./profile.service";
import { UtilitiesService } from "./utilities.service";
import { UserActivityService } from "./user-activity.service";

import { Answer } from "../interfaces/answer";
import { Study } from "../interfaces/study";

interface LogEntry { [propName: string]: any };
interface key {
	usk: string; // a user secret key
	studyID: string; // a study ID
}

@Injectable({
  providedIn: 'root'
})
export class LogService {
	private currentStudy: Study;
	public currentStudySubject: BehaviorSubject<Study>;

	private logQueryObservable; // queries FS for the mood history
	private currentEntry: LogEntry; // tracks state
	private isCurrentEntrySaved: boolean;
	currentEntrySubject: BehaviorSubject<LogEntry>;

	allEntriesSubject: BehaviorSubject<LogEntry[]>;
	responseRateSubject: BehaviorSubject<number>; // reports how up to date the log is
	logStatusSubject: BehaviorSubject<any>; // emits an object, numCreditedEntries, lastEntryTimestamp (may be undefined)
	isFirstEntrySubject: BehaviorSubject<boolean>;

	private secretKey: string;
	private uid: string;
	private keySubject = new Subject<key>();
	private newUserSubect = new Subject<string>(); // used to watch for account creation for new users

	private authObserver

  constructor(
		public afAuth: AngularFireAuth,
		public afStore: AngularFirestore,
		private bg: UserActivityService,
		public profile: ProfileService,
		public utils: UtilitiesService
	) {
    console.log('Hello Log Service');
		/**
		 * Set up data subscriptions
		 * */
		this.currentStudySubject = new BehaviorSubject(null)
		this.allEntriesSubject = new BehaviorSubject(null)
		this.currentEntrySubject = new BehaviorSubject(null)
		this.isCurrentEntrySaved = false
		this.responseRateSubject = new BehaviorSubject(0)
		this.logStatusSubject = new BehaviorSubject(null)
		this.isFirstEntrySubject = new BehaviorSubject(false)


		this.logQueryObservable = this.keySubject.pipe(
			// tap(key => console.log("log service key change",key)),
			switchMap(key =>
				this.afStore.collection<LogEntry>('logEntries', ref =>
					ref.where('usk', '==', key.usk)
					.where('studyID', '==', key.studyID)
					.orderBy("timestamp", "desc") // most recent first
					// .limit(100)
				).valueChanges()
			))


		// ASSUMPTION: We're setting both the history and the
		// current log entry. If the latest item in history is recent
		// enough, it becomes the current entry. If not, create a
		// new current entry.
		// NOTE: The "default" entry history is loaded from the mocks
		// the normal case is that a user will have a entry history
		// this is not true for first-time users, thus the if clause.
		this.logQueryObservable.subscribe( res => {
			// console.log( "log entries:", res )
			// console.log( "bg timeout", bg.moodTimeoutPeriod/(60*1000) )
			// res.forEach(item => console.log( new Date(item.timestamp)) )
			// update user history
			this.allEntriesSubject.next(res)
			this.calcResponseRate(res)

			// Set the default current mood
			if(res.length > 0) { // user has a entry history
				// IF the most recent entry's timestamp
				//    has id === currententry, or // DISABLED
				//    timestamp in cutoff time window (10 minutes)
				//        set in user-activity-provider
				// set the current entry to that entry.
				let tmp = res[0]
				// first check if the ids match // DISABLED
				// if(this.currentEntry.id && tmp.id === this.currentEntry.id){
				// 	this.currentEntry = tmp
				// 	// console.log( "log sets current mood to history item" )
				// } else
				if (tmp.timestamp > Date.now() - bg.moodTimeoutPeriod){
						this.currentEntry = tmp
				} else {
					// The DEBUG version makes the most recent log entry
					// the current entry regardless of timestamp
					// this.currentEntry = tmp // DEBUG
					// PRODUCTION: set current entry to a skeleton
					this.currentEntry = this.createEntrySkeleton(this.secretKey)
				}
			} else { // user does not have a history
				this.currentEntry = this.createEntrySkeleton(this.secretKey)
				this.isFirstEntrySubject.next(true)
			}
			this.currentEntrySubject.next(this.currentEntry)
		})


		this.profile.studyChanged.subscribe(signal => {
			// console.warn( "log sees studyChanged signal", signal )
			this.responseRateSubject.next(100)
			this.allEntriesSubject.next([])
			let logStatus = {
				responseRate: 100,
				duration: 1,
				isSuccessful: true,
				isFinished: false
			}
			this.profile.requestUpdateSettings(logStatus)
		})


		/**
		 * Set up user activity watchers
		 * */
		// app is hidden/unhidden
		this.bg.isHiddenSubject.subscribe(isHidden => {
			if(! this.uid) return
			if(! this.currentEntry) return
			if(isHidden){
				console.log( "page going into hiding, saving entry" )
				this.currentEntry['autosaveOnHide'] = Date.now()
				this.updateFirebase()
			} else {
				// console.log( "page coming out of hiding, check to refresh current entry" )
				if (this.currentEntry && this.currentEntry.hasOwnProperty('timestamp') &&
					this.currentEntry.timestamp > Date.now() - bg.moodTimeoutPeriod){
					// console.log( "    current entry still current", this.currentEntry )
				} else {
					// console.log( "    refreshing current entry" )
					// this will blank any existing answers; probably not what we want
					this.refreshCurrentEntry()
				}
			}
		})

		// autosave
		this.bg.autosaveSubject.subscribe(res => {
			if(! this.uid) return
			if(! this.currentEntry) return
			// console.log( "autosave", res )
			if(!this.isCurrentEntrySaved){
				// console.log( "   current entry unsaved, saving now" )
				this.currentEntry['autosaveOnTimer'] = Date.now()
				this.updateFirebase()
			} else {
				// console.log( "   current entry already saved, not saving" )
			}
		})

		// mood refresh
		this.bg.moodTimeoutSubject.subscribe(res =>{
			if(! this.uid) return
			if(! this.currentEntry) return
			// console.log( "moodTimeout", res , "refreshing mood")
			this.refreshCurrentEntry()
		})


		// new user
		const newUserWatcher = this.newUserSubect.pipe(
			// tap(uid => console.log( "log uid change",uid )  ),
			switchMap(uid =>
				this.afStore.collection('participants').doc(uid).valueChanges())
		)

		// triggers when user changes, OR when user profile changes
		newUserWatcher.subscribe(res => {
			if(res){
				this.setUserParams(res)
			}
		})

		this.authObserver = afAuth.authState.subscribe( user => {
			if(user) {
				if(user.uid !== this.uid){
					this.newUserSubect.next(user.uid)
					this.uid = user.uid
				}
			} else {
				this.uid = null
			}
		}) // end auth guard
	} // end constructor


	/**
	 * setUserParams
	 * @param userData a user profile object
	 * Checks for/loads study/survey, then updates
	 * the secret key. If the secret key changed,
	 * update the log subscription.
	 **/
	private setUserParams(userData){
		// console.log( userData )
		if(! userData.secretKey) return
		if(userData.studyID){
			this.setStudyFromID(userData.studyID) // checks if studyID has changed
				.then(res => {
					if(res){
						if( userData.secretKey !== this.secretKey){
							this.secretKey = userData.secretKey
							let key = {
								usk: userData.secretKey,
								studyID: userData.studyID
							}
							this.keySubject.next(key)
						}
					} else {
						// no study found for the given studyID
					}
				})
		} else {
			if(userData.isOnboardingComplete) {
				console.warn( "user does not have studyID", userData)
			}
		}
	}


	/**
	 * setStudyFromID
	 * @param studyID a studyID
	 * sets the current study to the given study ID
			// this.currentStudy = MockStudies[0]
			// this.currentStudySubject.next(MockStudies[0])
	 * */
	public setStudyFromID(studyID: string): Promise<any>{
		// console.log( "looking for" , studyID )
		if(this.currentStudy && studyID === this.currentStudy.studyID){
			// console.log( "same as current study" )
			return new Promise((resolve) => { resolve(this.currentStudy) })
		}
		return this.getStudyFromID(studyID)
		.then(res => {
			if(res){
				this.currentStudy = res
				this.currentStudy.duration = Number(res.duration)
				if(isNaN(this.currentStudy.duration)){
					console.warn( "Study with NaN duration, defaulting to 21", res )
					this.currentStudy.duration = 21
				}
				this.currentStudySubject.next(this.currentStudy)
				this.allEntriesSubject.pipe(take(1)).subscribe(log =>
					this.calcResponseRate(log) )
				if(res.questionBlocks && res.questionBlocks.length){
					this.bg.setMoodTimeout( Math.max(120, 
						res.questionBlocks.length*10))
				}
			}
			return res
		})
		// .then( res => {
		// 	// if the above search found something, return it
		// 	if(res){ return res }
		// 	// else search by name
		// 	console.log( "search by ID failed, fallback to searching by name" )
		// 	return this.getStudyFromName(studyID).then( nameRes => {
		// 		if(res){
		// 			this.currentStudy = res
		// 			this.currentStudy.duration = Number(res.duration)
		// 			if(isNaN(this.currentStudy.duration)){
		// 				console.warn( "Study with NaN duration, defaulting to 21", res )
		// 				this.currentStudy.duration = 21
		// 			}
		// 			this.currentStudySubject.next(this.currentStudy)
		// 			this.allEntriesSubject.take(1).subscribe(log =>
		// 				this.calcResponseRate(log) )
		// 		}
		// 		return res
		// 	})
		// })

	}


	/**
	 * getStudyfromId
	 * @param studyID
	 * returns the study, if it exists, else returns
	 * null or an error message.
	 **/
	public getStudyFromID(studyID: string): Promise<any>{
		return this.afStore.firestore.collection('studies')
		.where('studyID', '==', studyID).get()
		.then(snap => {
			// console.log( snap )
			if(!snap.size){
				// console.log( "No study matches user studyID", studyID  )
				return
			}
			if(snap.size > 1) {
				console.warn( "multiple studies match studyID", studyID )
				return
			}
			let ans = undefined
			snap.forEach(doc => {
				if(!ans){ // only use first item HACK
					// console.log( doc.data() )
					ans = doc.data()
				}
			})
			return ans
		}).catch(err => {
			console.warn( "couldn't get study", err )
		})
	}


	/**
	 * getStudyfromName
	 * @param studyName
	 * returns the study, if it exists, else returns
	 * null or an error message.
	 **/
	public getStudyFromName(studyName: string): Promise<any>{
		return this.afStore.firestore.collection('studies')
		.where('studyName', '==', studyName).get()
		.then(snap => {
			if(!snap.size){
				// console.warn( "No study matches user studyID", studyID  )
				return
			}
			if(snap.size > 1) {
				console.warn( "multiple studies match study NAME", studyName)
				return
			}
			let ans = undefined
			snap.forEach(doc => {
				if(!ans){ // only use first item HACK
					ans = doc.data()
				}
			})
			return ans
		}).catch(err => {
			console.warn( "couldn't get study", err )
		})
	}


	/**
	 * mockLog
	 *
	 * */
	// private mockLog(){
	// 	let mocks = []
	// 	let tmpTime = Date.now() - 32*24*60*60*1000
	// 	while(tmpTime < Date.now()){
	// 		let mockEntry = {usk: 'foobar', timestamp: tmpTime} as LogEntry
	// 		mocks.push(mockEntry)
	// 		tmpTime +=  6*60*60*1000
	// 	}
	// 	this.allEntriesSubject.next(mocks)
	// }


	/**
	 * createEntrySkeleton
	 * @param key
	 * @return LogEntry
	 * Given a user's secret key (defaults to current users secret key),
	 * create and return a skeleton log entry
	 * */
	createEntrySkeleton(key?: string): LogEntry{
		if(! key) key = this.secretKey
		let tmp = {
			id: null, // entry id
			usk: key,

			isComplete: false,
			timestamp: Date.now(),
			answers: [], // skeleton needs to have this; other functions depend on its existence
		} as LogEntry;
			return tmp
	}


	/**
	 * refreshCurrentEntry
	 * Called when an event suggests the current mood should be refreshed
	 * Events are i.e. inactivity timeouts
	 *
	 * A separate timer controls autosave. If a refresh occurs before
	 * an autosave, the current mood is lost.
	 * */
	private refreshCurrentEntry(){
		console.warn( "log: current entry refreshed" )
		this.currentEntry = this.createEntrySkeleton(this.secretKey)
		this.isCurrentEntrySaved = false
		this.currentEntrySubject.next(this.currentEntry)
	}


	/**
	 * addStudyDetailToEntry
	 * add studyID to the log entry
	 * since data is async, this gets called from several places
	 **/
	private addStudyDetailToEntry(){
		if(! this.currentStudy){
			console.warn( "trying to add study details before study is loaded" )
			return
		}
		if(!this.currentEntry.studyID){
			this.currentEntry.studyID = this.currentStudy.studyID
		} else {
			if(this.currentEntry.studyID !== this.currentStudy.studyID){
				console.warn( "Resetting study ID on a log entry" )
			}
			this.currentEntry.studyID = this.currentStudy.studyID
		}
	}


	/**
	 * recordAnswer
	 * @param ans a log entry answer (circumplex, slider, text, ...)
	 *  or array of such answers
	 * update the internal model of the current log entry, and
	 * broadcast it back to the display components.
	 * */
	public recordAnswer(ans: Answer | Answer[]){
		this.isCurrentEntrySaved = false
		let tmp
		if(!Array.isArray(ans)){
			tmp = [ans]
		} else { tmp = ans }
		// tmp.forEach(item => console.log( item) )
		tmp.forEach(item => this.updateLog(item) )
		this.currentEntry.isComplete = this.isEntryComplete()
		// calc response rate fails here because we need to pass
		// in the entire log
		// if(this.currentEntry.isComplete){
		// 	this.calcResponseRate()
		// }
		this.currentEntrySubject.next(this.currentEntry)
		this.bg.resetAutosave()
	}



	/**
	 * updateLog
	 * @param ans an answer object
	 * add a single answer item to the log, including
	 * parameter checking and setting background items.
	 * */
	private updateLog(ans: Answer){
		// search answer array. If this answer is found, update it
		// if not, add it
		if(this.currentEntry['isLocked']){
			// console.warn( "attempting to change an answer in a locked log entry" )
			return
		}
		let isUpdate = false
		this.currentEntry.answers.forEach(item => {
			if(item.blockID == ans.blockID && item.qid === ans.qid){
				isUpdate = true
				Object.assign(item, ans)
			}
		})
		if(!isUpdate){
			this.currentEntry.answers.push(ans)
		}
	}

	/** 
	 * lockCurrentEntry
	 *
	 * Flag the current entry as complete. Blocks any edits or upates
	 * to the entry.
	 *
	 * Should it only work if entry is complete? 
	 * undecided. Leave that question to the caller
	 * if needed, I could replace this with an option to set the lock
	 **/
	public lockCurrentEntry(){
		if(this.currentEntry.isComplete){
			this.currentEntry.isLocked = true
			this.currentEntrySubject.next(this.currentEntry)
			// noop
		} else {
			console.warn( "attempt to lock a non-completed entry" )
		}
	}

	/**
	 * updateFirebase
	 * request that the current log entry get saved to firebase
	 * first perform some safety checks
	 * */
	public updateFirebase(){
		// console.log( this.currentEntry )
		if(!this.currentEntry) return
		if(this.currentEntry.answers.length === 0) return
		// check if keys do not match; should never happen
		if(this.currentEntry.usk !== this.secretKey){
			console.warn( "Aborting; keys don't match", this.currentEntry)
			return
		}
		if(!this.currentStudy){
			console.warn( "cannot save log entry before study is loaded" )
			return
		}
		this.addStudyDetailToEntry()
		this.writeEntryToFirebase()
	}


	/**
	 * writeEntryToFirebase
	 * Save the current log entry to Firebase
	 * */
	private writeEntryToFirebase(entry?: LogEntry){
		if(! entry) entry = this.currentEntry
		// console.log( "writing log to firebase", entry )
		// Clean out spurious data
		let spuriousKeys = ["selected", "cx", "cy", "fill",
			"radius"]
		Object.keys(entry).forEach(key => {
			if(spuriousKeys.indexOf(key) > -1) {
				delete entry[key]
			}
		})
		Object.keys(entry).forEach(key => {
			if(entry[key] === undefined){
				console.warn( "invalid entry,", key , " is undefined.")
				delete entry[key]
			}
		})
		// set timestamp
		this.currentEntry.timestamp = Date.now()
		let docRef
		if(entry.id){
			docRef = this.afStore.firestore.collection('logEntries').doc(entry.id)
		} else {
			docRef = this.afStore.firestore.collection('logEntries').doc()
			entry.id = docRef.id
		}
		// console.log( "writing log to firebase", entry )
		docRef.set(entry).then(res => {
			this.isCurrentEntrySaved = true
		}).catch(err => {
			console.warn('Problem recording entry', entry)
		})
	}


	/**************   Utilities     ********
	 *
	 * Counting, sorting, cleanup
	 *
	 * ****************************************************************/

	/**
	 * calcResponseRate
	 * @param log an array of log entries
	 * Calculates the participants response
	 * rate, and also marks if the participant
	 * is finished with the study.
	 * Updates user settings to show the response rate etc.
	 * either log or current study could be null,
	 * since the base subscriptions both call
	 * this (to deal with async timing issues).
	 * */
	calcResponseRate(log: LogEntry[]){
		// console.log( "calculating response rate")
		// console.log( "calculating response rate", log )
		if(! this.currentStudy) return
		if(!log || log.length === 0) return
		// console.log( log )
		
		// check if finished
		let numDays = this.calcDuration(log, Date.now())
		let isFinished = false

		// duration is supposed to be a number, but the console
		// log shows it as a string. This forces the conversion.
		if(numDays > Number(this.currentStudy.duration)){
			isFinished = true
		}

		// calc start of logging
		let firstEntryTimestamp = Date.now()
		log.forEach(entry => {
			if(entry.timestamp < firstEntryTimestamp) firstEntryTimestamp = entry.timestamp
		})
		// === DEBUG ===
		// compute last valid day of log
		let cutoff = this.calcLastDay(log)
		// cutoff is 1 second before midnight. Let's give them a grace period
		let grace = 15*60*1000
		// in pathological cases, this.currentEntry is undefined. Pulling the
		// ID here and using the brackets for the ['id'] prevents a runtime error
		let curEntryID=this.currentEntry ? this.currentEntry['id'] : undefined 
		let validLog = log.filter(entry => entry.timestamp < cutoff + grace)
			.filter(entry => entry.isComplete || entry.id === curEntryID)

		let rrate = 100 // default value
		if(validLog.length > 0) {
			let maxNumResponses = this.calcMaxFromLog(log, Math.min(Date.now(), cutoff))
			let numResponses = this.calcNumResponses(validLog)
			rrate = 100 * numResponses/maxNumResponses
			if(rrate < 0){
				console.warn( "problem calculating response rate", rrate )
				rrate = 1
			}
			let logStatus = {
				numCreditedEntries: numResponses,
				lastEntryTimestamp: this.currentEntry ? this.currentEntry.timestamp : validLog[0].timestamp,
				firstEntryTimestamp: firstEntryTimestamp,
				// the calendar component also uses logservice.calcLastDay
				// to calc the last day.
				studyEndTimestamp: this.calcLastDay(
					[{timestamp: firstEntryTimestamp}]
				)
			}
			// log.forEach(entry => console.log( new Date(entry.timestamp) )  )
			// validLog.forEach(entry => console.warn( new Date(entry.timestamp) )  )
			
			// syncronize the "numNotifications" field to the calculated max
			// number of responses.
			if(maxNumResponses){
				logStatus['numNotifications'] = maxNumResponses
			}
			this.logStatusSubject.next(logStatus)
			// console.log( numResponses, maxNumResponses, rrate )
			// and compute response rate (as percent, i.e. between 0 and 100
		}
		// safety catch; be generous and give them full marks.
		if(isNaN(rrate)) rrate = 100
		let isOk = rrate >= this.currentStudy.minResponse
		// console.log( numDays, log.length, maxNumResponses )
		// broadcast results
		this.responseRateSubject.next(rrate)
		// update user profile
		let logStatus = {
			responseRate: rrate,
			duration: numDays,
			isSuccessful: isOk,
			isFinished: isFinished
		}
		this.profile.requestUpdateSettings(logStatus)
	}


	/**
	 * calcDuration
	 * @param log an array of log entries
	 * @param endDate <optional> the last day to consider. Defaults
	 *      to the last day in the log
	 * @return duration the time offset from the first
	 *      entry until today
	 * endDate paramenter lets this compute either the duration
	 * of the log, or the delta from the first day of the log
	 * until an arbitrary time. This is useful if a user hasn't logged
	 * recently, and we want to check if the study period is over.
	 * The function safety checks that end date comes after the
	 * first log entry.
	 * */
	calcDuration(log: LogEntry, endDate?): number {
		let startDate = Date.now()
		if(endDate === undefined) endDate = 0
		// find min, max timestamp in the log
		log.forEach(entry => {
			if(entry.timestamp < startDate) startDate = entry.timestamp
			if(entry.timestamp > endDate) endDate = entry.timestamp
		})
		if(endDate < startDate) endDate = startDate
		// compute number of days from the min/max
		return timeDay.count(startDate, endDate)
	}


	/**
	 * calcMaxFromLog
	 * @param log an array of log entries
	 * @param endDate <optional> the last day to consider. Defaults
	 *      to the last day in the log
	 * @return maxNum the max number of entries possible
	 * Algo:
	 *   First day: min(num entries, study frequency)
	 *   Days between first day and min(end Date, today):
	 *        study frequency * num days
	 *   If today < endDate
	 *     # notifications prior to next notification (or frequency)
	 *   */
	calcMaxFromLog(log: LogEntry[], endDate?): number {
		// log.forEach(item => console.log( new Date(item.timestamp ) ))
		// log.sort(this.safeSortByTimestamp).forEach(entry => console.log( new Date(entry.timestamp) ))
		let dayFormat = timeFormat("%Y-%m-%d");
		// endDate defaults to today
		if(!endDate){ endDate = Date.now() }
		// it is not possible to log in the future
		if(endDate > Date.now()){ endDate = Date.now() }
		// find first timestamp in the log
		let first = Date.now()
		log.forEach(d => {
			if(d.timestamp < first) first = d.timestamp
		})
		// sanity check
		if(endDate < first){
			console.warn( "calcMax request when log starts after end date", first, endDate, log )
			endDate = first
		}

		let numPossible = 0
		// count number possible on first day
		let firstS = dayFormat(new Date(first))
		numPossible += Math.min(this.currentStudy.frequency,
			log.filter(e => dayFormat(e.timestamp) === firstS).length)

		// count number of full days to endDate
		let numDays = timeDay.count(first, endDate)
		// console.log(" num days", numDays )
		// if the end date in the log is today, subtract one from numDays and
		// use schedule to count  today's num possible.
		// If not, then the end day will be counted when we multiply
		//    numDays by frequency.
		// EXCEPT if today is also the first day, in which case do nothing
		if(numDays && dayFormat(endDate) === dayFormat(new Date())){
			// console.log( "today is current date" )
			numDays--
			let schedule = this.profile.getLocalNotificationTimes()
			if(schedule.length === 0){
				console.warn( "problem with notifications schedule; using defaults", schedule )
				schedule = this.utils.getDefaultSchedule(this.currentStudy.frequency)
			}
			let todayPossible = this.utils.numNotifications(schedule)
			if(todayPossible === -1) todayPossible = this.currentStudy.frequency
			numPossible += Number(todayPossible)
		}
		// finally add the middle days
		numPossible += Number(numDays * this.currentStudy.frequency)
		return numPossible
	}


	/**
	 * calcNumResponses
	 * @param log an array of log entries
	 * @return num the number of credited entries
	 * User gets credit for up to studyFrequency entries per day
	 * for the duration of the survey, not afterwards
	 *   */
	private calcNumResponses(log: LogEntry[]): number {
		let dayFormat = timeFormat("%Y-%m-%d");
		// rollup log by day and count notifications per day
		return nest()
		.key(d => dayFormat(new Date(d.timestamp)))
		.rollup(d => {
			// d is all log entries which match date "d"
			// console.log("numRes is min", d.length, this.currentStudy.frequency)
			return Math.min(d.length, this.currentStudy.frequency)
		}).entries(log)
		.reduce((sum, current) => sum + current.value, 0)
	}


	/**
	 * calcLastDay
	 * @param log an array of log entries
	 * @return timestamp set for 23:59.59 of the last valid
	 * day of the study.
	 * Searchers log for first entry and adds the study duration
	 * to its day.
	 * setHours returns a timestamp
	 *
	 * */
	calcLastDay(log: LogEntry[]): number{
		if(!this.currentStudy){
			console.warn( "calcLastDay called when study is not set" )
			return Date.now()
		}
		let startDate = Date.now()
		let duration = Number (this.currentStudy.duration)
		log.forEach(entry => {
			if(entry.timestamp < startDate) startDate = entry.timestamp
		})
		// === DEBUG ===
		// let foo = timeDay.offset(startDate, duration).setHours(23,59,59)
		// console.log( new Date(startDate), duration, new Date(foo) )
		// ===  END  ===
		return timeDay.offset(startDate, duration)
			.setHours(23,59,59)
	}


	/**
	 * isEntryComplete
	 * @returns boolean
	 * Tests if the currentEntry has answered all the questions
	 * in the currentStudy.
	 * */
	private isEntryComplete(): boolean{
		if(!this.currentEntry || !this.currentEntry.answers) return false
		let isEntryComplete = true
		this.currentStudy.questionBlocks.forEach(block => {
			let tmp = this.currentEntry.answers.filter(item =>
				item.blockID === block.blockID).length
			if(block.scale === 'circumplex'){
				if(tmp < 2) {
					isEntryComplete = false
					block.isComplete = false
					// console.log( "failed on block", block.blockID,block.scale )
				} else {
					block.isComplete = true
				}
			} else {
				if (tmp < block.questions.length){
					isEntryComplete = false
					block.isComplete = false
					// console.log( "failed on block", block.blockID,block.scale )
				} else {
					block.isComplete = true
				}
			}
		})
		return isEntryComplete
	}


	/**
	 * safeSortByTimestamp
	 * @params a,b
	 * sorts based on timestamp. The safety check ensures that
	 * Date objects are converted to timestamps prior to the
	 * sorting comparision
	 * */
	safeSortByTimestamp(a,b){
		let aval = a.timestamp
		let bval = b.timestamp
		if(a.timestamp instanceof Date)
			aval = a.timestamp.getTime()
		if(b.timestamp instanceof Date)
			bval = b.timestamp.getTime()
		return aval - bval // oldest first
	}


	/**
	 * ngOnDestroy
	 * clean up subscriptions
	 * */
	ngOnDestroy(){
		console.log( "goodby log provider" )
		this.authObserver.unsubscribe()
		// this.moodsQueryObservable.unsubscribe()
	}

}
