// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,

  firebaseProd: {
    apiKey: "AIzaSyDAtQNQGqnHO8_gRG2yu6p8jVnzqVtsnTg",
    authDomain: "studymanager-prod.firebaseapp.com",
    projectId: "studymanager-prod",
    storageBucket: "studymanager-prod.appspot.com",
    messagingSenderId: "602765589044",
    appId: "1:602765589044:web:0d108ab67e9db8c005a861"
  },
  firebaseDev: {
    apiKey: "AIzaSyBQ0crP0istmSxwbUwKNtyntxX6mV9okTs",
    authDomain: "studymanagerdev-21571.firebaseapp.com",
    databaseURL: "https://studymanagerdev-21571.firebaseio.com",
    projectId: "studymanagerdev-21571",
    storageBucket: "studymanagerdev-21571.appspot.com",
    messagingSenderId: "133647772218",
    appId: "1:133647772218:web:7ab3bba6958611f2c8a303"
  },
	firebaseOrig: {
    apiKey: "AIzaSyCErSt1Adqafz_d0jIEBQXJtxKuNNjC0zc",
    authDomain: "studymanagerportal.firebaseapp.com",
    databaseURL: "https://studymanagerportal.firebaseio.com",
    projectId: "studymanagerportal",
    storageBucket: "studymanagerportal.appspot.com",
    messagingSenderId: "864405080412"
	}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
