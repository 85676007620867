import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import 'firebase/firestore';

import { Subject } from "rxjs";
// import { pipe } from 'rxjs';
import { takeUntil } from 'rxjs/operators/';

import PhoneNumber from 'awesome-phonenumber'

import { ProfileService } from "./profile.service";
import { LogService } from "./log.service";
import { UtilitiesService } from "./utilities.service";

import { Participant } from "../interfaces/participant";

const FB_COLLECTION_NAME: string = 'notificationDocs'

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
	// doc which stores notifications. Was 'emails'

	private isAuth: boolean = false
	private isStudyFinished: boolean = false; // used as a flag to block token updates

	private notificationDocData: Participant = {}  // gets written to notification doc, not to profile
	private localDocChanged: boolean = false // TRUE if the local notification doc has been modified in any way

  constructor(
		afAuth: AngularFireAuth,
		public afStore: AngularFirestore,
		public userSettings: ProfileService,
		public log: LogService,
		public utils: UtilitiesService
	) {
		console.log('Hello Messaging Service');
		// Service worker is desired for off-line usage even without messaging
		if('serviceWorker' in navigator){
			navigator.serviceWorker.register('/service-worker.js')
				.catch(err => {  
					console.warn( "error registering service worker." )
				})
		}

		// set up subscriptions and user data
		afAuth.authState.subscribe(user => {
			let authData = {}
			if (user) {
				this.isAuth = true
				authData = {
					uid: user.uid,
					email: user.email,
					source: 'trier',
				}
				if(user.displayName) {
					authData['displayName'] = user.displayName
				}
				this.notificationDocData = 
					Object.assign(this.notificationDocData, 
						authData)
				// console.log( "auth changes doc" )
				this.localDocChanged = true 
			} else {
				this.isAuth = false
			}
		}) // end auth guard

		// used to 
		// set notification type
		// clear tokens if user has completed survey
		// syncs local version of doc with user settings for phone, notification type
		userSettings.userSettings.subscribe(settings => {
			// console.log( "messaging service sees settings update", settings )
			if(! settings) return // settings is null at init
			if(settings.isFinished) {
				this.isStudyFinished = true
				this.removeUserTokens(settings.uid)
			}
			// check if settings have changed anything
			if(settings.notificationType &&
				this.notificationDocData['notificationType'] !== settings.notificationType
			){
				this.notificationDocData['notificationType'] = settings.notificationType
				this.localDocChanged = true 
			} else {
				// default to email
				this.notificationDocData['notificationType'] = 'email'
			}
			if(settings.phoneNumber && settings.phoneNumber.length > 4){
				let pn = new PhoneNumber(settings.phoneNumber)
				if( this.notificationDocData['phoneNumber'] !== pn.getNumber('e164') ){
					this.notificationDocData['phoneNumber'] = pn.getNumber('e164')
					this.localDocChanged = true 
				}
			}
		})


		userSettings.studyChanged.subscribe(signal => {
			// console.log( "messaging sees studyChanged signal", signal )
			this.removeUserTokens()
		})

		// this gets called for every page/component which incorporates this
		// service. Notifiations/onboarding, and study/log entry, for examples
		// logStatusSubject emits 
		//    numCreditedEntries, first entry time, last entry time, ...
		log.logStatusSubject.subscribe(res => {
			if(res){
				// console.warn( "might updating num credited entries", { ...res } )
				if(this.notificationDocData['uid'] !== undefined){
					// console.warn( "updating num credited entries", res )
					this.updateNotificationDoc(res)
				}
			}
		})

		// Purpose of this watcher was to auto-update the notification doc
		// replaced it with an explicity function call.
		// log.currentEntrySubject.subscribe(currentEntry => {
		// 	// Use "isLocked" instead of isComplete 
		// 	// because we would only update the notification doc when the entry is finished
		// 	// what about a user who abandons an entry? Then this would not update the
		// 	// notification doc with the number of completed entries.
		// 	if(currentEntry && 
		// 		currentEntry.hasOwnProperty('isLocked') && currentEntry.isLocked) {
		// 		this.notificationDocToFB()
		// 			.then(res => console.warn( "write notification doc to FB", res ) )
		// 	}
		// })


		// loads study parameters
		let gotStudy$: Subject<boolean> = new Subject<boolean>()
		log.currentStudySubject.pipe(takeUntil(gotStudy$)).subscribe(study => {
			if(! study) return
			// console.log( study )
			this.notificationDocData['studyID'] = study.studyID
			this.notificationDocData['studyNotificationsPerDay'] = study.frequency
			this.notificationDocData['isRandomTimes'] = study.isRandomTimes ? true:false
			this.notificationDocData['studyName'] = study.studyName
			this.notificationDocData['studyOwnerEmail'] = study.ownerEmail
			this.notificationDocData['studyOwnerInstitute'] = study.ownerUniversity.name
			this.notificationDocData['studyOwnerID'] = study.ownerID
			this.notificationDocData['studyFBID'] = study.studyFBID
			this.notificationDocData['isSMSAllowed'] = study.isSMSAllowed ? study.isSMSAllowed : false
			// this.notificationDocData['twilioSendingNumber'] = study.twilioSendingNumber
			let ownerSalutation =
				`${study.ownerTitle} ${study.ownerFirstName} ${study.ownerLastName}`
			if(ownerSalutation.trim().length === 0){
			}
			this.notificationDocData['studyOwnerSalutation'] = ownerSalutation
			// cancel the subscription.
				// console.log( "current study subject changes doc" )
			this.localDocChanged = true 
			gotStudy$.next(true)
		})

	}



	/**
	 * removeUserTokens
	 * @param uid the uid of the user whose tokens we remove
	 * Public interface.
	 * Removes the email sending tokens
	 * Somewhat ensures call to private function is safe.
	 * */
	public removeUserTokens(uid?: string): Promise<any> {
		if(! uid) { uid = this.notificationDocData['uid'] }
		return this.deleteUserTokens(uid)
	}


	/**
	 * deleteUserTokens
	 * @param collection one of emails or fcmTokens,
	 * delete all messaging tokens associated with the user
	 * effectively turning off notifications
	 * */
	private deleteUserTokens(uid: string): Promise<any>{
		 return this.afStore.firestore.collection(FB_COLLECTION_NAME)
		.where('uid', '==', uid).get()
		.then(tokens => {
			var batch = this.afStore.firestore.batch()
			tokens.forEach(tok => batch.delete(tok.ref) )
			batch.commit()
		})
	}


	/**
	 * setNextNotificationTime
	 *
	 * Sets the nextNotificationTime property of notificationDocData
	 * based on other properties of notificationDocData
	 *
	 * If the study uses random times, also set the following
	 *   - firstRandomNotification
	 *   - randomNotificationSchedule 
	 * */
	private setNextNotificationTime(){
		// tmpSchedule is used to  find the next notification time
		let tmpSchedule = this.notificationDocData['notificationSchedule']
		if(tmpSchedule === undefined){
			return
		}
		// overwrite tmpSchedule for random notifications
		// console.warn( this.notificationDocData )
		let numNotes = parseInt(this.notificationDocData.studyNotificationsPerDay)
		if(this.notificationDocData.isRandomTimes &&  numNotes){
			let minTime = Math.min(...tmpSchedule)
			if(Number.isInteger(minTime)){
				this.notificationDocData['firstRandomNotification'] = minTime
				// determine random schedule
				let rSchedule = this.utils.generateRandomSchedule(
					tmpSchedule, numNotes)
				// set random schedule
				if(rSchedule !== undefined){
					this.notificationDocData['randomNotificationSchedule'] = rSchedule
					tmpSchedule = rSchedule 
				}
			}
		}
		// should be an int, not a string
		this.notificationDocData['studyNotificationsPerDay'] = 
			parseInt(this.notificationDocData.studyNotificationsPerDay)
		this.notificationDocData['nextNotification'] = this.utils.findNextTime(tmpSchedule)

		console.log( "set notification time changes doc" )
		this.localDocChanged = true 
	}

	// forceRandomNotification(){
	// 	// if no notifications left today, but time left, force one
	// 	// let upcomingHourTime = this.localToServerTime(new Date().getHours()) + 1
	// 	let upcomingHourTime = (new Date().getHours()) + 1
	// 	let foo = rSchedule.sort((a, b) => a - b).filter(time => time >= upcomingHourTime)
	// 	if(foo.length == 0){ // no notifications left for today
	// 		let endTime = Math.max(...tmpSchedule)
	// 		// if time for more than one event
	// 		if(upcomingHourTime < endTime ){ 
	// 			let eventsLeft = Math.min(Math.round((endTime - upcomingHourTime)/2),
	// 				Math.round(this.notificationDocData.studyNotificationsPerDay/2))
	// 			let numEvents = Math.max(1,eventsLeft)
	// 			rSchedule = this.utils.generateRandomSchedule([upcomingHourTime, endTime], numEvents)
	// 		}

	// 	}
	// }


	/** 
	 * add phone number to notification doc
		 * @param number
		 *
		 * if number has length zero or is undefined,
		 * set notification type to email
		 *
		 * Update the phone number in the notification doc
		 * If the number is set, set notificationType to SMS
		 **/
	public updateSMSNumber(phoneNumber: string): Promise<any>{
		let pn = new PhoneNumber(phoneNumber)
		if(!phoneNumber || ! pn.isMobile()){
			this.notificationDocData['notificationType']='email'
		} else {
			this.notificationDocData['notificationType']='sms'
			this.notificationDocData['phoneNumber']=pn.getNumber('e164')
		}
		console.log( "change sms number changes doc" )
		this.localDocChanged = true 
		// console.log( "phone number saved to local state, not database" )
		const foo = new Promise((resolve) => { resolve(true) })
		return foo
		// return this.notificationDocToFB()
	}


	

	/**
	 * updateNotificationSchedule
	 * @param schedule
	 * Update the user data in the token to show the revised
	 * notification schedule. Does NOT save the schedule to 
	 * the user's Firebase profile; if the schedule is changed
	 * in the profile the notification doc will be out of sync
	 * */
	public updateNotificationSchedule(schedule: number[]): Promise<any> {
		console.log( "updating notification schedule:", schedule  )
		// error handling
		const err = new Promise((resolve) => { resolve(null) })
		if(!Array.isArray(schedule)) return err
		if(!schedule) return err // schedule may be undefined
		if(schedule.length === 0) return err

		// assumption is that notification schedule is in UTC
		this.notificationDocData['notificationSchedule'] = schedule
		// this next function also sets up the random schedule
		this.setNextNotificationTime()

		console.log( "set notification schedule changes doc" )
		this.localDocChanged = true
		return this.notificationDocToFB()
	}


	/**
	 * getNotificationSchedule
	 *
	 * Return the users notification schedule
	 * note: as currently written, the returned item may
	 * be out of sync with what is in the DB
	 * This could be fixed with a flag which checked if the 
	 * doc has been updated since the last sync.
	 **/
	 public getNotificationSchedule(): Promise<any>{
		 return Promise.resolve(this.notificationDocData)
	 }


	/**
	 * updateNotificationDoc
	 * @param updateObj
	 * @param method
	 *
	 * Update the notification doc with
	 * the supplied object.
	 *
	 * Updates include the number of notifications.
	 * */
	private updateNotificationDoc(updateObj){
		const err = new Promise((resolve) => { resolve(null) })
		let blacklist = ['token', 'uid']
		Object.keys(updateObj).forEach(key => {
			if(blacklist.indexOf(key) > -1) delete updateObj[key]
		})
		Object.keys(updateObj).forEach(key => {
			if(updateObj[key] != this.notificationDocData[key]){
				// console.log( "update notification doc changes doc" )
				// console.log( key, updateObj[key], this.notificationDocData[key] )
				this.localDocChanged = true
			}
			})
		Object.assign(this.notificationDocData, updateObj)

		// return this.notificationDocToFB()
		// .then(res => console.warn( "update notification docs to", res ) )
	}


	/**
	 * requestNotificaitonDocToFB
	 *
	 * Allows another page/service to request that the notification doc is updated
	 *
	 * I tried having this tied automatically to the log.currentStudySubject,
	 * but that proved unwieldy due to too frequent updates
	 *
	 * We only want this called when the current entry is locked; this 
	 * function allows us to implement such functionality
	 *
	 * All safety checks are (already) handled by the private function.
	 * */
	public requestNotificationDocToFB(): Promise <any> {
		return this.notificationDocToFB()
		// .then(res => console.warn( "write notification doc to FB", res ) )
	}

	/**
	 * notificationDocToFB
	 *
	 * Given a collection, search if the collection contains
	 * a doc matching this.notificationDocData.uid. 
	 *
	 * If so, update that doc to match this.notificationDocData
	 *
	 * If not, add a new doc with that data
	 *
	 * @return promise with the name of the modified collection
	 **/
	private notificationDocToFB(): Promise<any>{
		const err = new Promise((resolve) => { resolve(null) })
		// if(this.isStudyFinished) return err // don't update the token if the user is finished

		if(this.notificationDocData['uid'] === undefined){
			return err // don't update the token if the user isn't loaded
		}
		if(!this.localDocChanged){
			console.log( "local doc unchanged, not writing to firestore" )
			return err
		}
		return this.afStore.firestore.collection(FB_COLLECTION_NAME)
		.where("uid", "==", this.notificationDocData['uid']).get()
		.then(snaps => {
			// generate a new docID, in case the user doesn't have one
			let docRef = this.afStore.firestore.collection(FB_COLLECTION_NAME).doc().id
			// if they do already have one, this will set the doc id to the last
			// one in the database. Ideally, they would only have one doc, but
			// it seems we get more errors than one might expect.
			if(snaps.size > 0){
				snaps.forEach(doc => {
					docRef = doc.id // or get the one from the snapshot
				})
			}
			return docRef
		}).then(docRef => {
			// safety-- clean undefined data
			Object.keys(this.notificationDocData).forEach(key => {
				if(this.notificationDocData[key] === 'undefined') {
					delete this.notificationDocData[key]
				}
			})
			// write this.notificationDocData to the doc
			// console.log( this.notificationDocData )
			return this.afStore.firestore.collection(FB_COLLECTION_NAME).doc(docRef)
				.set(this.notificationDocData, {merge: true})
				.then(res => {
					// console.log( FB_COLLECTION_NAME, " -- settings saved", 
					// 	this.notificationDocData )
					this.localDocChanged = false // and set local doc to unchanged
					return ("success")
				})
				.catch(err => console.warn(FB_COLLECTION_NAME,
					" -- error saving settings", err ))
		})
	}
}



/********** ARKIV **************/

	/**
	 * sendTestMessage
	 * Sends a test message to the user
	 * */
	// sendTestMessage(){
	// 	console.log( this.notificationDocData )
	// 	console.log( "sending test message" )
	// 	let msg = "Welcome to Moodus"
	// }
	

	/**
	 * getNotificationSchedule
	 * @param uid
	 * gets the notification schedule for the given user
	 * from Firebase and stores it internally
	 // * */
	// private getNotificationSchedule(uid?: string): Promise<any> {
		// if(! uid ) { uid = this.notificationDocData['uid'] }
		// return this.afStore.firestore.collection('participants')
		// .doc(uid).get()
		// .then(doc => {
			// let data = doc.data()
			// if(data && data.notificationSchedule){
				// this.notificationDocData['notificationSchedule'] = data.notificationSchedule
				// this.setNextNotificationTime()
			// }
			// if(data && data.isFinished){
				// this.isStudyFinished= true
			// }
		// }).catch(err => {
			// console.log( "error getting user notification schedule", uid )
		// })
	// }
	
	
	/**
	 * updateEmails
	 * Called on load if notifications are turned off.
	 * Checks if preconditions met; if so, updates the email settings.
	 *
	 * NO LONGER USED
	 * */
	// private updateEmails(): Promise<any> {
	// 	// console.log( "updating emails", this.notificationDocData )
	// 	const err = new Promise((resolve) => { resolve(null) })
	// 	if(! this.isAuth) { return err  }
	// 	if(!this.notificationDocData['uid']) return err
	// 	return this.getNotificationSchedule()
	// 	.then( () => {
	// 		return this.notificationDocToFB()
	// 	}).then(res => {
	// 		console.log( "email notification schedule registered" )
	// 	})
	// 	.catch( err => console.warn('problem accessing or storing email notification schedule', err) )
	// }



