import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor() {
    console.log('Hello Utilities Provider');
  }

	/**
	 * localToServerTime
	 * @param hour an integer from 0 to 23
	 * Cleans input and returns the hour in server (UTC)
	 * or local time
	 * */
	public localToServerTime(hour): number {
		// watch for rollover events
		if(typeof hour === 'string') hour = parseInt(hour)
		if(isNaN(hour)){ hour = 0 }
		let offset = new Date().getTimezoneOffset() / 60
		let answer = Math.round(hour + offset)
		while(answer > 24) answer -= 24
		while(answer < 0) answer += 24
		return answer
	}


	/**
	 * serverToLocalTime 
	 * @param hour an integer from 0 to 23
	 * Cleans input and returns the hour in server (UTC)
	 * or local time
	 * */
	public serverToLocalTime(hour): number{
		if(typeof hour === 'string') hour = parseInt(hour)
		if(isNaN(hour)){ hour = 0 }
		let offset = new Date().getTimezoneOffset() / 60
		let answer = Math.round(hour - offset)
		while(answer > 24) answer -= 24
		while(answer < 0) answer += 24
		return answer
	}


	/**
	 * isTimeWindowOpen/Closed
	 * @param schedule
	 * Given an array of notification times in UTC zone, 
	 * returns true if the current time is reasonably
	 * close to the next scheduled time
	 * else false.
	 *
	 * Reasonable: 15 minutes before an upcoming notification
	 *   to 1 hour after the most recent notification
	 *
	 * MOD as per David Rosenbaums request from 2022-01-15
	 *   to four hours
	 *
	 * WARNING! no error checking done on the schedule
	 * */
	public isTimeWindowOpen(schedule: number[]): boolean {
		/** Hack solution for David's single question survey
		 * which should have a four hour window
		 * see email of 2022-01-15
		 * */
		if(schedule.length === 1){
			let cutoffTime = new Date()
			// notification time is in UTC, convert to local hours
			// serverToLocalTime returns an integer in [0,23]
			const scheduleTime = this.serverToLocalTime(schedule[0])
			// setHours assumes the input is in local time, and adjusts UTC time
			cutoffTime.setHours(scheduleTime, 0, 0) // top of the hour

			// window opens 1 hour ahead
			let windowOpen = new Date(cutoffTime.getTime() - (1 * 60 * 60 * 1000))
			// window closes 4 hours later
			let windowClose = new Date(cutoffTime.getTime() + (4 * 60 * 60 * 1000))
			const currentTime = new Date()
			const isOpen = currentTime.getTime() > windowOpen.getTime() && currentTime.getTime() < windowClose.getTime()
			return isOpen
		}
			/** end hack **/
		
		let now = new Date()
		let nextNotification = this.serverToLocalTime(this.findNextTime(schedule))
		// 15 minutes before next notification
		if(now.getHours() == nextNotification - 1) {
			if(now.getMinutes() > 44) {
				return true
			}
		}
		let lastNotification = this.serverToLocalTime(this.findLastTime(schedule))
		if(now.getHours() == lastNotification) {
			return true
		}
		return false
	}

	isTimeWindowClosed(schedule: number[]): boolean {
		return(! this.isTimeWindowOpen(schedule))
	}


	/** 
	 * findNextTime
	 * @param schedule
	 * Given an array of notification times in UTC zone, 
	 * return the next time in UTC.
	 * WARNING! no error checking done on the schedule
	 * */
	public findNextTime(schedule: number[]): number {
		if(schedule.length==1){
			return(schedule[0])
		}
		const now = new Date()
		let target = this.localToServerTime(now.getHours())
		if(now.getMinutes() > 44) { target = target + 1 }
		let foo = schedule.sort((a, b) => a - b).filter(time => time > target)
		return foo.length ? foo[0] : schedule[0]
	}


	/** 
	 * findLastTime
	 * @param schedule
	 * Given an array of notification times in UTC zone, 
	 * return the last time in UTC.
	 * WARNING! no error checking done on the schedule
	 * */
	 public findLastTime(schedule: number[]): number {
		if(schedule.length==1){
			return(schedule[0])
		}
		let target = this.localToServerTime(new Date().getHours()) 
		let foo = schedule.sort((a, b) => a - b).filter(time => time <= target)
		return foo.length ? foo[foo.length -1 ] : schedule[schedule.length -1]
	}


	/**
	 * getRandomInt
	 *
	 * Returns a random integer between min (inclusive) and max (inclusive).
	 * Using Math.round() will give you a non-uniform distribution!
	 */
	public getRandomInt(min: number, max: number): number {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}


	/**
	 * generateRandomSchedule
	 *
	 * @param timeFrame an array containing a start and a stop time
	 * Returns an object containing the new random schedule
	 * and the first notification time.
	 *
	 * Returned object is empty if error.
		// function generateRandomSchedule(timeFrame, numEvents){
	 **/
	public generateRandomSchedule(timeFrame: number[], numEvents: number): number[]{
		if(! Number.isInteger(numEvents) ){
			console.warn("number of events is malformed or undefined", numEvents)
			return(undefined)
		}
		if (timeFrame.length != 2){
			console.warn("malformed notification time frame, length is not 2)", timeFrame)
			return(undefined)
		}
		let randomSchedule = []
		let startTime = Math.min(...timeFrame)
		let endTime = Math.max(...timeFrame)
		if((endTime - startTime) < numEvents){
			console.warn( "malformed notification time frame, random notificaiton time window is too small", 
				timeFrame, numEvents )
			return(undefined)
		}
		// fill an array with all possible values
		randomSchedule = Array.from(Array(endTime - startTime + 1).keys())
			.map((_, idx) =>  startTime + idx)
			// Array(endTime - startTime + 1).fill().map((_, idx) =>  startTime + idx)
		// then randomly knock them out, one at a time
		while(randomSchedule.length > numEvents){
			// console.log( randomSchedule) 
			let tmp = this.getRandomInt(1,randomSchedule.length) - 1
			randomSchedule.splice(tmp,1)
		}
		return randomSchedule
	}


	/**
	 * numNotifications
	 * @param schedule an array of notification times
	 * @return the number of notifications prior to the
	 * current time or -1 if we are at the end of the list
	 * ASSUMES SCHEDULE TIMES ARE LOCAL TO THE CURRENT MACHINE
	 * */
	public numNotifications(schedule: number[]): number {
		let now = new Date().getHours()
		let nextTime = schedule.sort((a,b) => a - b).find(x => x > now)
		return schedule.indexOf(nextTime)
		}


	/**
	 * getDefaultSchedule
	 * @param frequency
	 * @return a notification schedule in LOCAL time
	 * Sensible default notification times
	 * */
	public getDefaultSchedule(freq: number): number[]{
		let sched = [9, 11, 13, 15, 18, 21]
		switch(freq){
			case 1: { sched = [12]; break; }
			case 2: { sched = [9, 21]; break; }
			case 3: { sched = [9, 15, 21]; break; }
			case 4: { sched = [9, 12, 18, 21]; break; }
			case 5: { sched = [9, 12, 15, 18, 21]; break; }
		}
		return sched
	}

}
